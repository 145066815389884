import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

const Crystal: React.FC = () => {
  const crystalRef = useRef<THREE.Mesh>(null);

  useFrame(() => {
    if (crystalRef.current) {
      crystalRef.current.rotation.x += 0.01;
      crystalRef.current.rotation.y += 0.02;
    }
  });

  return (
    <mesh ref={crystalRef} position={[0, -1, 0]}>
      <icosahedronGeometry args={[1, 1]} />
      <meshStandardMaterial
        color="white"
        roughness={0.1}
        metalness={1}
        envMapIntensity={1}
      />
    </mesh>
  );
};

export default Crystal;
