import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Stars, Text, useGLTF } from "@react-three/drei";
import Crystal from "./Crystal";
import Lights from "./Lights";
import { EffectComposer, Bloom } from "@react-three/postprocessing";
import * as THREE from "three"; // Ensure THREE is imported

import { useFrame } from "@react-three/fiber";
import { useRef, useState } from "react";

import Lottie from "react-lottie";
import loading from "./loading.json";

function Model() {
  // Use the OBJLoader to load the .obj file
  // eslint-disable-next-line
  const { nodes, materials, animations } = useGLTF(
    "/assets/Intergalactic_Spaceship.glb"
  );

  const shipMesh = nodes.Ship as THREE.Mesh; // Type casting to Mesh object

  const meshRef = useRef<THREE.Mesh>(null); // eslint-disable-next-line
  const [position, setPosition] = useState<THREE.Vector3>(
    new THREE.Vector3(-5, 0, 0)
  ); // eslint-disable-next-line
  const [rotation, setRotation] = useState<THREE.Euler>(
    new THREE.Euler(1.2, -0.2, -1.5)
  );

  const mobileScale = window.innerWidth < 1024 ? 0.0025 : 0.001;

  useFrame(() => {
    if (meshRef.current) {
      if (window.innerWidth < 1024) {
        meshRef.current.position.x += 0.01;
        meshRef.current.rotation.x +=
          Math.sin(meshRef.current.position.x) * 0.0005;
      } else {
        meshRef.current.position.x += 0.04;
        meshRef.current.rotation.x +=
          Math.sin(meshRef.current.position.x) * 0.005;
      }
      if (meshRef.current.position.x > 0) {
        meshRef.current.scale.x -= 5 * 0.000000001;
      }
      if (
        meshRef.current.position.x > 10 ||
        (window.innerWidth < 1024 && meshRef.current.position.x > 5)
      ) {
        meshRef.current.position.x = window.innerWidth < 1024 ? -5 : -10;
        meshRef.current.position.y = Math.random() * 3 - 1; // Randomize y position
        meshRef.current.position.z = Math.random() * 3 - 1; // Randomize z position
        meshRef.current.scale.x = 0.001;
      }
    }
  });

  return (
    <mesh
      ref={meshRef}
      castShadow
      receiveShadow
      geometry={shipMesh.geometry}
      material={materials["Material.001"]}
      scale={[mobileScale, mobileScale, mobileScale]} // Adjust the scale to make it smaller
      position={position}
      rotation={rotation}
    />
  );
}
const App: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Suspense
      fallback={<Lottie options={defaultOptions} height={400} width={400} />}
    >
      <div
        style={{
          minHeight: "100vh",
          background: "radial-gradient(circle, #000020, #302974)",
        }}
      >
        <div className="h-screen">
          <Canvas camera={{ position: [0, 0, 5], fov: 75 }}>
            <Model />
            <EffectComposer>
              <Bloom
                intensity={0.8}
                luminanceThreshold={0.2}
                luminanceSmoothing={0.9}
              />
            </EffectComposer>
            <Stars
              radius={300}
              depth={60}
              count={5000}
              factor={7}
              saturation={0}
              fade
              speed={1}
            />

            <ambientLight intensity={0.2} />
            <Lights />

            <Crystal />

            <Text
              font="/Roboto-Bold.ttf" // Make sure to load a font
              fontSize={window.innerWidth < 1024 ? 0.4 : 1}
              position={[0, 2, 0]}
              color="white"
              anchorX="center"
              anchorY="middle"
            >
              Dimension Crafters
            </Text>

            {/* <OrbitControls /> */}
          </Canvas>
        </div>
        <div className="text-white text-center w-full p-8 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold mb-4">Other Projects</h2>
          <ul className="space-y-2">
            <li>
              <a
                href="https://besmarty.dimencraft.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-lg font-medium hover:underline"
              >
                Besmarty
              </a>
            </li>
            <li>
              <a
                href="https://portal.dimencraft.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-lg font-medium hover:underline"
              >
                Portal
              </a>
            </li>
            <li>
              <a
                href="https://river.dimencraft.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-lg font-medium hover:underline"
              >
                River
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Suspense>
  );
};

export default App;
